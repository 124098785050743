#page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 0 auto;
}

