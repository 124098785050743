#nav {
  border: none;
  box-shadow: none;

  ul {
    width: 10rem;
  }

  a {
    font-weight: 600;
    font-size: 1.2rem;
    color: black;
  }
}

#side-nav {
  a {
    font-weight: bold;
    color: black;
    width: 50vw;
  }
}
